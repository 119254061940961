<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <div v-else v-html="pageContent.html"></div>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  name: "DinamicInfoPage",
  props: ['pageName'],
  data() {
    return {
      loading: true,
      pageContent: "",
    };
  },
  mounted() {
    if (!this.pageName) {
      this.redirectToHome();
      return;
    } else {
      this.getPageContent();
    }
  },
  methods: {
    getPageContent() {
      //save the pageName in a variable and convert it to lower case
      let pageName = this.pageName.toLowerCase();
      fire.database()
        .ref("admin/infoPages/" + pageName)
        .once("value")
        .then(snapshot => {
          if (!snapshot.val()) {
            this.redirectToHome();
            return;
          }
          this.pageContent = snapshot.val();
          this.loading = false;
          console.log('this.pageContent.metaData', this.pageContent.metaData)
          //forse update metaInfo
          this.$meta().refresh();
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.redirectToHome();
        });
    },
    redirectToHome() {
      this.$router.push("/");
    }
  },
  metaInfo() {
    if (!this.pageContent.metaData) {
      return {
        title: 'go24.co.il'
      }
    } else {
      return {
        title: this.pageContent.metaData.title || 'go24.co.il',
        meta: [
          {
            author: this.pageContent.metaData.author || 'go24',
            description: this.pageContent.metaData.description || 'go24.co.il',
            keywords: this.pageContent.metaData.keywords || 'go24.co.il',
            viewport: this.pageContent.metaData.viewport || 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
            robots: this.pageContent.metaData.robots || 'index, follow',
            canonical: this.pageContent.metaData.canonical || 'go24.co.il',
            referrer: this.pageContent.metaData.referrer || 'go24.co.il',
          }
        ]
      }
    }
  }
};
</script>
